import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { AboutsSaga } from "./saga";
import * as selectors from "./selectors";
import { toastSuccess, toastError } from "../../../utils/toastHelper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./style.module.css";
import ReactSelect from "react-select";

const AboutEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: AboutsSaga });

  const About = useSelector(selectors.About) || {};

  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  const [errors, setErrors] = useState({});

  // Populate `formData` when editing
  useEffect(() => {
    if (About?._id) {
      setFormData({
        title: About.title || "",
        content: About.content || "",
        type: About?.type,
        status: About.status ? "Active" : "Inactive",
      });
    }
  }, [About]);

  // Fetch About data by ID
  useEffect(() => {
    const url = new URL(window.location.href);
    const linkId = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);

    if (linkId !== "add") {
      dispatch(actions.fetchAboutById(linkId));
    } else {
      dispatch(actions.resetAbout());
    }
  }, [dispatch]);

  // Handle input changes
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChangeSelect = (event, name) => {
    if (event == null) {
      setFormData((prevData) => ({ ...prevData, [name]: null }));
    }
    if (event && event.value != "") {
      setFormData((prevData) => ({ ...prevData, [name]: event.value }));
    }
  };
  // Validate form fields
  const validateForm = () => {
    const errors = {};
    if (!formData.title) {
      errors.title = "Title is required.";
    }
    if (!formData.content) {
      errors.content = "Content is required.";
    }
    if (!formData.type) {
      errors.type = "Type is required";
    }
    if (!formData.status) {
      errors.status = "Status is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the form
    if (!validateForm()) {
      toastError("Please fill out all required fields.");
      return;
    }

    const payload = {
      data: { ...formData,
        status: formData.status == "Active" ? true : false,
       },
      onSuccess: () => {
        toastSuccess(
          About?._id
            ? "About updated successfully"
            : "About created successfully"
        );
        setFormData({ title: "", content: "" });
        setErrors({});
        navigate("/dashboard/admin/about");
      },
    };

    if (About?._id) {
      dispatch(actions.updateAbout({ ...payload, data: { ...formData, id: About._id ,status: formData.status == "Active" ? true : false,} }));
    } else {
      dispatch(actions.createAbout(payload));
    }
  };
  // Unescape HTML for saving back to the state
  const unescapeHtml = (escapedHtml) =>
    escapedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

  return (
    <div className={`${styles.rowStyle} mt-5`}>
      <h3 className="formheadingText">{About?._id ? "Edit" : "Add"} Knowledge Resources</h3>
      <Row>
        <Col lg={12}>
          <Form.Group controlId="title">
            <Form.Label>
              <span className="headingText">Title</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              maxLength={255}
              onChange={(e) => handleInputChange("title", e.target.value)}
              className={`form-control ${errors.title ? "is-invalid" : "is-valid"}`}
              required
            />
            <div className="invalid-feedback">{errors.title}</div>
          </Form.Group>
        </Col>
        </Row>

<Row>
        <Col lg={12}>
          <Form.Group controlId="content">
            <Form.Label>
              <span className="headingText">Content</span>
            </Form.Label>

        

            <ReactQuill
              value={formData.content}
              // onChange={(value) => handleInputChange("content", value)}
              onChange={(content) => handleInputChange("content", unescapeHtml(content)) } 
              className={`form-control ${errors.content ? "is-invalid" : "is-valid"}`}
            /> 
            <div className="invalid-feedback">{errors.content}</div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col lg={6}>
          <Form.Group controlId="type">
            <Form.Label>
              <span className="headingText">Link type</span>
            </Form.Label>
            <ReactSelect
              value={{ value: formData.type, label: formData.type }}
              onChange={(e) => {
                handleChangeSelect(e, "type");
              }}
              options={[
                { value: "medicinalPlants", label: "Medicinal plants" },
                { value: "agroTechniques", label: "Agro-techniques" },
              ]}
              placeholder="Select a type"
              name="type"
              className={`form-control ${errors.type && "is-invalid"} ${
                errors.type === false && "is-valid"
              }`}
            />
            <small className={`${errors.type && "invalid-feedback"} `}>
              {!!errors.type && errors.type}
            </small>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group controlId="status">
            <Form.Label>
              <span className="headingText">Status</span>
            </Form.Label>
            <ReactSelect
              value={{
                value: formData.status,
                label: formData.status,
              }}
              onChange={(e) => {
                handleChangeSelect(e, "status");
              }}
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
              ]}
              placeholder="Select a status"
              name="status"
              className={`form-control ${errors.status && "is-invalid"} ${
                errors.status === false && "is-valid"
              }`}
            />
            <small className={`${errors.status && "invalid-feedback"} `}>
              {!!errors.status && errors.status}
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button
              variant="danger"
              style={{ marginLeft: "1em" }}
              onClick={() => navigate("/dashboard/admin/about")}
            >
              Back
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutEdit;


