import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  AboutList:{links:[],totalRecords:0},
};

const AboutList = createSlice({
  name: "AboutList",
  initialState,
  reducers: {
    fetchAbouts(state, action) {
      state.loading = true;
    },
    fetchAboutsSuccess(state, action) {
      state.AboutList = action.payload; 
      state.loading = false;
    },
    fetchAboutsFailure(state, action) {
      state.loading = false;
    },
    removeAbout(state, action) {
      state.loading = true;
    },
    removeAboutSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeAboutFailure(state, action) {
      state.loading = false;
    },
  },
 
});

export const { actions, reducer, name: sliceKey } = AboutList;
