import { Col, Container, Row,Button } from "react-bootstrap";
import UsersCustomTable from "../../../components/dashboardApp/adminCustomTable/userTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";
import styles from "./links.module.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aboutListingSaga } from "./saga";
import { sliceKey, reducer, actions, initialState } from "./slice";
import * as selectors from "./selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { toastSuccess } from "../../../utils/toastHelper";
import { confirmAlert } from 'react-confirm-alert';


const AboutAdmin =()=>{
  
  const navigate = useNavigate();

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: aboutListingSaga });
  const dispatch = useDispatch();
  const dbAboutList = useSelector(selectors.selectAboutList) || initialState;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "createdAt", direction: "desc" });

  const totalCount = dbAboutList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    const payload = {
      page: currentPage - 1,
      limit: itemsPerPage,
      sortField:sortConfig.key,
      sortOrder:sortConfig.direction
    };
    dispatch(actions.fetchAbouts(payload));
  
  }, [dispatch,  currentPage, itemsPerPage, sortConfig]);
  const handleAboutEdit=(linkId)=>{
    // Find the entry to be editedTitle
    navigate(`/dashboard/admin/about/edit/${linkId}`);

    
  };
  const handleAboutDelete = (linkId) => {

    confirmAlert({
      message: `Are you sure you want to delete this ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            const payload = {
              data: {
                id: linkId,
              },
              onSuccess: () => {
                toastSuccess("About Deleted successfully");
                const payload = {
                  page: currentPage - 1,
                  limit: itemsPerPage,
                  sortField:sortConfig.key,
                  sortOrder:sortConfig.direction
                };
                dispatch(actions.fetchAbouts(payload));
                navigate("/dashboard/admin/about");
              },
            };
            dispatch(actions.removeAbout(payload));
          }
        },
        {
          label: 'No'
        }
      ]
    });
   
  };

  



const allAboutsList = [];
const aboutHeadings = [
  {
    Sno:"SNo",
    Title:"Title",
    Type: "Type",
    
  },
];

if (dbAboutList.aboutItems && dbAboutList.aboutItems.length) {
  for (let i = 0; i < dbAboutList.aboutItems.length; i++) {
    
    allAboutsList.push({
      aboutId:dbAboutList.aboutItems[i]["_id"],
      title:dbAboutList.aboutItems[i]["title"],
       type: dbAboutList.aboutItems[i]["type"],
      
    });
    
  }
}





  return (
    <>
  <section>
        <Container fluid>
          <Row>
            <Col lg={10} className={styles.marginBottom36}>
              <h2>Knowledge Resources</h2>
            </Col>
          

            <Col lg={2} className={`text-right ` }>
            {/* <Mandi className='nav-link' to="/dashboard/admin/links/add">
            Add
          </Mandi> */}
              <Button variant="primary" style={{position:"absolute", top:"89px", right:"48px"}} 
              onClick={() => navigate("/dashboard/admin/about/add", { state: { changeAboutData: dbAboutList } })}>
 
              {/* // onClick={() => navigate("/dashboard/admin/mandi/add")} 
              // changeMandiData={changeMandi} >
                Add */}
                Add
              </Button>
            </Col>
         
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <UsersCustomTable
                heading={aboutHeadings}
                filteredData={allAboutsList}
                onRowClick={handleAboutEdit}
                handleLinkDelete={handleAboutDelete}
                type={"about"}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>

    </>
  );
};

export default AboutAdmin;