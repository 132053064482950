// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .customTable_headT__oHVcK{
 cursor: pointer;
    &:hover{
        opacity: 2.5;
    font-weight: 900;
    filter: brightness(0.3);
    }/* padding: 1.5em !important; */
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #4A5568;
}
.customTable_greenT__TVOr2{
    color: #25855A;
}
.customTable_greenT__TVOr2:hover {
    color: #25855A;
}
.customTable_greenT__TVOr2:active {
    color: #25855A;
}
.customTable_dataT__\\+N6kc{
    padding: 1.5em !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    /* display: flex; */
    color: #2D3748;

}
.customTable_pt_1__0eSWP{
    padding-top: 10px;
}
.customTable_badgeHigh__9-icT{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #22543D;
    padding: 0px 4px;
    background: #C6F6D5 !important;
    border-radius: 2px;
}
#customTable_basic-addon1__bcoji{
    background: #EDF2F7;
    /* gray/200 */

    border: 1px solid #E2E8F0;
    border-radius: 4px 0px 0px 4px;
}


`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/customTable/customTable.module.css"],"names":[],"mappings":"EAAE;CACD,eAAe;IACZ;QACI,YAAY;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,CAAC,+BAA+B;IAChC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;;AAElB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,aAAa;;IAEb,yBAAyB;IACzB,8BAA8B;AAClC","sourcesContent":["  .headT{\n cursor: pointer;\n    &:hover{\n        opacity: 2.5;\n    font-weight: 900;\n    filter: brightness(0.3);\n    }/* padding: 1.5em !important; */\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    align-items: center;\n    color: #4A5568;\n}\n.greenT{\n    color: #25855A;\n}\n.greenT:hover {\n    color: #25855A;\n}\n.greenT:active {\n    color: #25855A;\n}\n.dataT{\n    padding: 1.5em !important;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    align-items: center;\n    /* display: flex; */\n    color: #2D3748;\n\n}\n.pt_1{\n    padding-top: 10px;\n}\n.badgeHigh{\n    font-style: normal;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 16px;\n    text-transform: uppercase;\n    color: #22543D;\n    padding: 0px 4px;\n    background: #C6F6D5 !important;\n    border-radius: 2px;\n}\n#basic-addon1{\n    background: #EDF2F7;\n    /* gray/200 */\n\n    border: 1px solid #E2E8F0;\n    border-radius: 4px 0px 0px 4px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headT": `customTable_headT__oHVcK`,
	"greenT": `customTable_greenT__TVOr2`,
	"dataT": `customTable_dataT__+N6kc`,
	"pt_1": `customTable_pt_1__0eSWP`,
	"badgeHigh": `customTable_badgeHigh__9-icT`,
	"basic-addon1": `customTable_basic-addon1__bcoji`
};
export default ___CSS_LOADER_EXPORT___;
