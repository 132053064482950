import React, { useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../../../../assets/icons/deleteIconred.svg";
import styles from "./style.module.css";
import Dropzone from "react-dropzone";

const ImageForm = ({ formData, handleFileChange, saveImages, previousStep }) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);

  const handleDrop = (acceptedFiles) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"];
    const filteredFiles = acceptedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (filteredFiles.length !== acceptedFiles.length) {
      setFileError("Only .jpg, .jpeg, .png, .svg, and .webp files are allowed");
      return;
    }

    const updatedFiles = filteredFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setFileError(null);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleNext = () => {
    if (files.length === 0) {
      setFileError("Please select an image");
    } else {
      setFileError(null);
      saveImages(files);
    }
  };

  const handleImageChange = (event) => {
    const fileList = Array.from(event.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"];
    const filteredFiles = fileList.filter((file) => allowedTypes.includes(file.type));

    if (filteredFiles.length !== fileList.length) {
      setFileError("Only .jpg, .jpeg, .png, .svg, and .webp files are allowed");
      return;
    }

    const updatedFiles = filteredFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles((prevImages) => prevImages.concat(updatedFiles));
    setFileError(null);
  };

  return (
    <div className={[styles.rowStyle, "mt-5"].join(" ")}>
      <Row>
        <Col lg={12} className="mt-5">
          <h3 className="formheadingText mb-3">Upload Images</h3>
          <Dropzone
            onDrop={handleDrop}
            accept=".jpeg,.jpg,.png,.svg,.webp" // Update accepted file types
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.dropzone}>
                <input
                  {...getInputProps()}
                  required
                  accept=".jpeg,.jpg,.png,.svg,.webp" // Update accepted file types
                  multiple
                  className={`form-control ${
                    fileError && "is-invalid"
                  } ${fileError === false && "is-valid"}`}
                  onChange={handleImageChange}
                />
                <p>
                  Drag and drop files or{" "}
                  <span className="text-success">Browse</span>
                </p>
              </div>
            )}
          </Dropzone>
          <div>
            <small
              className={`${fileError && "invalid-feedback"} `}
              style={{ display: "unset" }}
            >
              {!!fileError && fileError}
            </small>
          </div>
          <div className="row">
            <div className={[styles.previewContainer].join(" ")}>
              {files.map((file, index) => (
                <div
                  className={[styles.preview, "col-md-2"].join(" ")}
                  style={{ textAlign: "center" }}
                  key={index}
                >
                  <Image
                    src={file.preview}
                    thumbnail={true}
                    style={{ height: "8rem" }}
                  />
                  <p style={{ color: "#4A5568" }}>
                    {file.name}
                    <Link
                      to={"#"}
                      style={{ textDecoration: "none" }}
                      onClick={() => handleRemove(index)}
                    >
                      <img src={deleteIcon} style={{ width: "20px" }} />
                    </Link>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-5">
          <div className="float-end">
            <Button variant="secondary" onClick={previousStep}>
              Previous
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "1em" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageForm;
