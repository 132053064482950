import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchAboutById({ payload }) {
  try {
    const res = yield call(API.fetchAboutData, payload);    
    const parsedObject = JSON.parse(res.data);    
   
    const { body } = parsedObject;
    yield put(actions.fetchAboutByIdSuccess(body));
  } catch (err) {
    yield put(actions.fetchAboutByIdFailure(err?.response?.data));
  }
}



export function* handleCreateAbout({ payload }) {
  try {
    const res = yield call(API.createAboutData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.createAboutSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.createAboutFailure(err?.response?.data));
  }
}

export function* handleUpdateAbout({ payload }) {
  try {
   
    const res = yield call(API.updateAboutData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { body } = parsedObject;
    yield put(actions.updateAboutSuccess(body));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.updateAboutFailure(err?.response?.data));
  }
}

export function* handleDeleteAbout({ payload }) {
  try {
    const res = yield call(API.removeAboutData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.removeAboutSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeAboutFailure(err?.response?.data));
  }
}





/**
 * Root saga manages watcher lifecycle
 */
export function* AboutsSaga() {
  yield takeLatest(actions.fetchAboutById.type, handleFetchAboutById);
  yield takeLatest(actions.createAbout.type, handleCreateAbout);
  yield takeLatest(actions.updateAbout.type, handleUpdateAbout);
  yield takeLatest(actions.removeAbout.type, handleDeleteAbout);
}
