import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Card, CardContent, FormGroup } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import otpVerifiedIcon from "../../../../assets/icons/otp-verified.svg";
import { Input } from "@mui/material";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import OtpComponent from "./OtpContents";
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";
import LegalDetails from "./LegalDetails";
import BankDetails from "./BankDetails";
import RegistrationSuccessfulPage from "./RegistrationSuccessful";
import FormSubmittionPage from "./FormSubmittedPage";
import { registrationSaga } from "./saga";
import { sliceKey, reducer, actions } from "./slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";
import { useNavigate } from "react-router";
import { useAuth } from "../../../contexts/auth";
import routes from "../../../../routes";
import PreLoader from "../../../components/shared/preLoader";



export default function RegistrationPage() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: registrationSaga });

  const selectStyles = {
    select: {
      borderRadius: "8px",
      height: "48px",
    },
  };
  const buttonStyles = {
    root: {
      width: "276px",
      height: "48px",
      borderRadius: "6px",
      backgroundColor: "green !important",
    },
  };

  const CustomButton = withStyles(buttonStyles)(({ classes, ...props }) => (
    <Button className={classes.root} {...props} />
  ));

  // CustomSelect component with applied styles
  const CustomSelect = withStyles(selectStyles)(({ classes, ...props }) => (
    <Select className={classes.select} {...props} />
  ));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [selectedValue, setSelectedValue] = useState("buyer");
  const [age, setAge] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [otpButtonClicked, setOtpButtonClicked] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const [showlegalDocuments, setShowLegalDocuments] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [showBankDetails, setBankDetails] = useState(false);
  const [showRegsitrationSuccessfulPage, setShowRegistrationSuccessfulPage] =
    useState(false);
  const [showSubmitPage, setShowSubmitPage] = useState(false);
  const cardStyle = {
    width: "840px",
    height: "auto",
    padding: "24px",
  };

  // const [selectedValue, setSelectedValue] = useState("");
  const [typeError, setTypeError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const userId = useSelector(selectors.selectUserId);

  const generateOtpInProgress = useSelector(selectors.generateOtpInProgress);

  useEffect(() => {
    if (loggedInUser) {
      navigate(routes.home.landing);
    }
  }, [navigate, loggedInUser]);

  const handleDropdownChange = (event) => {
    console.log(event.target.value);
    setAge(event.target.value);
    setCategoryError("");
  };
  const emailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };
  const mobileChange = (event) => {
    setMobile(event.target.value);
    setPhoneError("");
  };
  const checkPhoneNumber = (phoneNumber) => {
    const pattern = /^\d{10}$/;
    return pattern.test(phoneNumber);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setTypeError("");
  };

  const generateOtpForResend = () => {
    const phone = mobile;
    const payload = {
      data: {
        phone,
        state: "purpose_register",
      },
    };
    dispatch(actions.generateOtp(payload));
  };

  const generateOtp = () => {
    const phone = mobile;
    const payload = {
      data: {
        phone,
        state: "purpose_register",
      },
      onSuccess: () => {
        setOtpButtonClicked(!otpButtonClicked);
      },
    };
    dispatch(actions.generateOtp(payload));
  };

  const onClickOtpButton = () => {
    if (selectedValue.trim() === "") {
      setTypeError("Type is required.");
    }
    if (email.length > 0) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setEmailError("Invalid email");
        return;
      }
    }
    if (mobile.trim() === "") {
      setPhoneError("Mobile number is required.");
    } else {
      if (checkPhoneNumber(mobile.trim())) {
        setPhoneError("");
      } else {
        console.log("not");
        setPhoneError("10 digits Mobile number.");
        return;
      }
    }
    if (age.trim() === "") {
      setCategoryError("Choose Category.");
    }

    if (
      selectedValue.trim() !== "" ||
      email.trim() !== "" ||
      mobile.trim() !== "" ||
      age.trim() !== ""
    ) {
      const payload = {
        data: {
          accountType: selectedValue,
          accountCategory: age,
          email,
          phone: mobile,
        },
        onSuccess: () => {
          generateOtp(mobile);
        },
      };
      dispatch(actions.saveContactDetails(payload));
    }
  };

  const onBackButtonClick = () => {
    setOtpButtonClicked(false);
  };
  const onVerifyButtonClick = (requestedOtp) => {
    const payload = {
      data: {
        phone: mobile,
        state: "purpose_register",
        requestedOtp,
      },
      onSuccess: () => {
        setOtpVerified(true);
        setShowPersonalDetails(!showPersonalDetails);
      },
    };

    dispatch(actions.validateOtp(payload));
  };

  const onClickPersonalDetailsNextButton = (
    firmName,
    authorisedEmail,
    personName,
    authorisedPhone
  ) => {
    const payload = {
      data: {
        id: userId,
        companyName: firmName,
        userName: personName,
        authorisedEmail: authorisedEmail,
        authorisedPhone: authorisedPhone,
      },
      onSuccess: () => {
        setShowLegalDocuments(true);
      },
    };
    dispatch(actions.savePersonalDetails(payload));
  };

  const onClickPersonalDetailsBackButton = () => {
    setOtpButtonClicked(false);
    setOtpVerified(false);
    setShowPersonalDetails(false);
    setShowAddressDetails(false);
    setBankDetails(false);
  };

  const onClickLegalDocumentsNextButton = (
    aadharNumber,
    gstNumber,
    panNumber,
    aadharToUpload,
    gstToUpload,
    panToUpload
  ) => {
    const payload = {
      data: {
        id: userId,
        aadharNumber,
        gst: gstNumber,
        pan: panNumber,
      },
      onSuccess: () => {
        setShowAddressDetails(true);
        setShowLegalDocuments(false);
        setShowPersonalDetails(false);
        setOtpButtonClicked(false);
      },
    };

    if (aadharToUpload) payload.data.aadharCertificate = aadharToUpload;
    if (panToUpload) payload.data.panCertificate = panToUpload;
    if (gstToUpload) payload.data.gstCertificate = gstToUpload;

    dispatch(actions.saveLegalDetails(payload));
  };
  const onClickLegalDocumentsSkipButton = (
  
  ) => {
    setShowAddressDetails(true);
    setShowLegalDocuments(false);
    setShowPersonalDetails(false);
    setOtpButtonClicked(false);

    // dispatch(actions.saveLegalDetails({}));
  };
  const onClickLegalDocumentsBackButton = () => {
    setOtpButtonClicked(true);
    setOtpVerified(true);
    setShowPersonalDetails(true);
    setShowLegalDocuments(false);
  };
  const onClickAddressDetailsBackButton = () => {
    setShowLegalDocuments(true);
  };
  const onClickAddressDetailsNextButton = (
    address,
    country,
    state,
    district,
    pincode,
    addressProof
  ) => {
    const payload = {
      data: {
        id: userId,
        address,
        country,
        state,
        district,
        pincode,
      },
      onSuccess: () => {
        setBankDetails(true);
        setShowAddressDetails(false);
      },
    };
    if (addressProof) payload.data.addressProof = addressProof;
    dispatch(actions.saveAddressDetails(payload));
  };
  const onClickAddressDetailsSkipButton = (
  ) => {
    setBankDetails(true);
    setShowAddressDetails(false);
    dispatch(actions.saveAddressDetails());
  };
  const onClickBankDetailsBackButton = () => {
    setShowAddressDetails(true);
    setBankDetails(false);
  };
  const onClickBankDetailsFinishButton = (
    bankName,
    branchName,
    ifsc,
    accountNumber,
    accountHolderName,
    cancelledChecque
  ) => {
      const payload = {
        data: {
          id: userId,
          bankName,
          branchName,
          ifsc,
          accountNumber,
          accountHolderName,
          newUser:true
        },
        onSuccess: () => {
          setShowRegistrationSuccessfulPage(true);
          setBankDetails(false);
        },
      };
      if (cancelledChecque) payload.data.cancelledChecque = cancelledChecque;
      dispatch(actions.saveBankDetails(payload));
  };
  const onClickReSubmitButton = () => {
    setShowRegistrationSuccessfulPage(true);
    setShowSubmitPage(false);
  };
  const onClickApproveButton = () => {
    setShowSubmitPage(true);
    setShowRegistrationSuccessfulPage(false);
  };
  useEffect(() => {
    if (otpVerified) {
      const timer = setTimeout(() => {
        setOtpVerified(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [otpVerified]);

  /*  return (
    <LegalDetails
      backButtonClicked={onClickLegalDocumentsBackButton}
      nextButtonClicked={onClickLegalDocumentsNextButton}
    />
  ); */
  return (
    <>
      {loggedInUser ? (
        <></>
      ) : (
        <>
          {generateOtpInProgress ? <PreLoader /> : null}
          <div
            style={{ marginTop: "20px" }}
            className={styles.registrationHeader}
          >
            Registration{" "}
            {selectedValue === "seller" ? "for Supplier" : "for Buyer"}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Card style={cardStyle}>
              <CardContent>
                {!otpButtonClicked &&
                  !otpVerified &&
                  !showAddressDetails &&
                  !showBankDetails &&
                  !showRegsitrationSuccessfulPage &&
                  !showSubmitPage && (
                    <>
                      <div className={styles.accountDetails}>
                        Account details
                      </div>
                      <div className={styles.verifyAccountDetails}>
                        Verify your account details
                      </div>
                      <div className="regform"
                         style={{
                          display:"flex" , flexWrap: "wrap", justifyContent: "space-between",}}>
                        <div>
                          <div className={styles.accountType}>Account type</div>
                          <div className={styles.topAlign}>
                            <RadioGroup
                              value={selectedValue}
                              onChange={handleChange}
                            >
                              <div style={{ dispay: "flex" }}>
                                <FormControlLabel
                                  value="buyer"
                                  control={
                                    <Radio
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "green",
                                        },
                                      }}
                                    />
                                  }
                                  label="Buyer"
                                />
                                <FormControlLabel
                                  value="seller"
                                  control={
                                    <Radio
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "green",
                                        },
                                      }}
                                    />
                                  }
                                  label="Supplier"
                                />
                              </div>
                            </RadioGroup>
                            {typeError && (
                              <FormHelperText error>{typeError}</FormHelperText>
                            )}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{ marginLeft: "8px" }}
                            className={styles.accountType}
                          >
                            Account category
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            <FormControl
                              sx={{ m: 1, minWidth: 276, borderRadius: "6px" }}
                            >
                              <CustomSelect
                                value={age}
                                onChange={handleDropdownChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                {selectedValue === "buyer" && (
                                  <MenuItem value={"GOVT_AGENCY"}>
                                    Govt. Agency
                                  </MenuItem>
                                )}
                                {selectedValue === "buyer" && (
                                  <MenuItem value={"PROCESSOR"}>
                                    Processor
                                  </MenuItem>
                                )}
                                {selectedValue === "buyer" && (
                                  <MenuItem value={"INSTITUTION"}>
                                    Institution
                                  </MenuItem>
                                )}
                                {selectedValue === "seller" && (
                                  <MenuItem value={"AGGREGATOR"}>
                                    Aggregator
                                  </MenuItem>
                                )}
                                {selectedValue === "seller" && (
                                  <MenuItem value={"FPO/FPC"}>FPO/FPC</MenuItem>
                                )}
                                {selectedValue === "seller" && (
                                  <MenuItem value={"Farmer"}>Farmer</MenuItem>
                                )}
                                {(selectedValue === "seller" ||
                                  selectedValue === "buyer") && (
                                  <MenuItem value={"TRADER"}>Trader</MenuItem>
                                )}
                              </CustomSelect>
                            </FormControl>
                            {categoryError && (
                              <FormHelperText
                                style={{ paddingLeft: "10px" }}
                                error
                              >
                                {categoryError}
                              </FormHelperText>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        <div id="remail">
                          <div className={styles.personalDetails}>
                            Email address
                          </div>
                          <div style={{ marginLeft: "-8px" }}>
                            <FormControl
                              sx={{ m: 1, width: "276px" }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                placeholder="Ex. demo@email.com"
                                inputProps={{
                                  "aria-label": "weight",
                                }}
                                value={email}
                                onChange={emailChange}
                              />
                            </FormControl>
                            {emailError && (
                              <FormHelperText
                                style={{ paddingLeft: "10px" }}
                                error
                              >
                                {emailError}
                              </FormHelperText>
                            )}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{ marginLeft: "8px" }}
                            className={styles.personalDetails}
                          >
                            Mobile number
                          </div>
                          <FormControl
                            sx={{ m: 1, width: "276px" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              aria-describedby="outlined-weight-helper-text"
                              placeholder="Ex. 99XXXXXXXX"
                              inputProps={{
                                "aria-label": "weight",
                                maxLength: 10,
                              }}
                              value={mobile}
                              onChange={mobileChange}
                            />
                          </FormControl>
                          {phoneError && (
                            <FormHelperText
                              style={{ paddingLeft: "10px" }}
                              error
                            >
                              {phoneError}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox disabled={true} defaultChecked />
                            }
                            label={
                              <>
                                <div style={{ display: "flex" }}>
                                  <div className={styles.termsAndConditions}>
                                    I agree to the
                                  </div>
                                  <div
                                    style={{ marginLeft: "4px" }}
                                    className={styles.termsAndConditionsLink}
                                  >
                                    terms and conditions
                                  </div>
                                  <div
                                    style={{ marginLeft: "4px" }}
                                    className={styles.termsAndConditions}
                                  >
                                    of the service
                                  </div>
                                </div>
                              </>
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="regbutt"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "50px",
                        }}
                      >
                        <CustomButton
                          onClick={onClickOtpButton}
                          variant="contained"
                        >
                          <div
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "28px",
                            }}
                          >
                            Get OTP
                          </div>
                        </CustomButton>
                      </div>
                    </>
                  )}
                {otpButtonClicked && !otpVerified && !showPersonalDetails && (
                  <OtpComponent
                    onBackButtonClick={onBackButtonClick}
                    onVerifyButtonClick={onVerifyButtonClick}
                    onClickOtpButton={generateOtpForResend}
                  />
                )}
                {otpVerified && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "180px",
                      }}
                    >
                      <img src={otpVerifiedIcon} alt="verifiedIcon" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                      className={styles.accountDetails}
                    >
                      OTP verified successfully!
                    </div>
                  </>
                )}
                {!otpVerified && showPersonalDetails && !showlegalDocuments && (
                  <PersonalDetails
                    mobile={mobile}
                    email={email}
                    clickedBackButton={onClickPersonalDetailsBackButton}
                    clickedNextButton={onClickPersonalDetailsNextButton}
                  />
                )}
                {showlegalDocuments && (
                  <LegalDetails
                    backButtonClicked={onClickLegalDocumentsBackButton}
                    nextButtonClicked={onClickLegalDocumentsNextButton}
                    skipButtonClicked={onClickLegalDocumentsSkipButton}
                  />
                )}
                {showAddressDetails &&
                  !showlegalDocuments &&
                  !showPersonalDetails && (
                    <AddressDetails
                      onClickNextButton={onClickAddressDetailsNextButton}
                      onClickBackButton={onClickAddressDetailsBackButton}
                      onClickSkipButton={onClickAddressDetailsSkipButton}
                    />
                  )}
                {showBankDetails && (
                  <BankDetails
                    onClickBackbutton={onClickBankDetailsBackButton}
                    onClickFinishbutton={onClickBankDetailsFinishButton}
                  />
                )}
                {/* {showRegsitrationSuccessfulPage && (
              <RegistrationSuccessfulPage
                onClickApproveButton={onClickApproveButton}
              />
            )} */}
                {/* {showSubmitPage && ( */}
                {showRegsitrationSuccessfulPage && (
                  <FormSubmittionPage
                    backToRegistrationPage={onClickReSubmitButton}
                  />
                )}
              </CardContent>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
