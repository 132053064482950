import React, { useEffect ,useState} from "react";
import styles from "./styles.module.css";
import Accordian from "../../../components/homeApp/accordian";
import AboutMedicinalFirst from "../../../components/aboutMedicinalFirst";
import AboutMedicinalSecond from "../../../components/aboutMedicinalSecond";
import AyushResearch from "../../../components/ayushResearch";
import MedicinalPlantsAssessment from "../../../components/MedicinalPlantsAssessment";
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { abotListingSaga } from './saga';
import { useDispatch, useSelector } from 'react-redux';
import { sliceKey, reducer, actions, initialState } from "./slice";
import * as selectors from "./selectors";

const details = [
  {
    title: "Indian medicinal plants database",
    component: <AboutMedicinalFirst />,
  },
  {
    title: "Database of medicinal plants",
    component: <AboutMedicinalSecond />,
  },
  {
    title: "Ayush research portal",
    component: <AyushResearch />,
  },
  {
    title: "Medicinal plants in India an assessment of their demand and supply",
    component: <MedicinalPlantsAssessment />,
  },
];

export default function AboutMedicinalPlants() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: abotListingSaga });
  const dispatch = useDispatch();
  const dbAboutList = useSelector(selectors.selectAboutList) || [];

  const [aboutData,SetAboutData] = useState([]);
  useEffect(() => {
    let filterItem =dbAboutList?.aboutItems?dbAboutList?.aboutItems.filter(item=>(item.type =="medicinalPlants"&& item.status==true)):[];

    SetAboutData(filterItem)
  }, [dbAboutList]);


  useEffect(() => {

    dispatch(actions.fetchAbouts());
  
  }, [dispatch]);
  const unescapeHtml = (escapedHtml) =>
    escapedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  return (
    <main className={styles.aboutMedicinalPlants}>
      <h1>About medicinal plants</h1>
      {aboutData.map((item, index) => (
        <Accordian key={index} title={item.title}>
          <div dangerouslySetInnerHTML={{ __html: unescapeHtml(item.content) }} />

        </Accordian>
      ))}
    </main>
  );
}
