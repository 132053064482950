import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  About:{},
};

const AboutsForAdmin = createSlice({
  name: "AboutsForAdmin",
  initialState,
  reducers: {
    resetAbout(state, action) {
      state.loading = false;
      state.error = null;
      state.About = {};
    },
    fetchAboutById(state, action) {
      state.loading = true;
    },
    fetchAboutByIdSuccess(state, action) {
      state.About = action.payload; 
      state.loading = false;
    },
    fetchAboutByIdFailure(state, action) {
      state.loading = false;
    },
    createAbout(state, action) {
      state.loading = true;
    },
    createAboutSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    createAboutFailure(state, action) {
      state.loading = false;
    },
    updateAbout(state, action) {
      state.loading = true;
    },
    updateAboutSuccess(state, action) {
      // state.Mandi = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    updateAboutFailure(state, action) {
      state.loading = false;
    },
    removeAbout(state, action) {
      state.loading = true;
    },
    removeAboutSuccess(state, action) {
      state.About = action.payload; //action.payload.data.document;
      state.loading = false;
    },
    removeAboutFailure(state, action) {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AboutsForAdmin;
