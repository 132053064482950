import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import styles from "./styles.module.css";
import SearchInput from "../../../components/homeApp/searchIntput";
import Dropdown from "../../../components/homeApp/dropdown";
import LiveAuctionCard from "../../../components/homeApp/liveAuctionCard";
import Pagination from "../../../components/homeApp/pagination";
import PreLoader from "../../../components/shared/preLoader";

// mocking images from api

const filterOptions = [
  { value: "live", label: "Live" },
  { value: "closed", label: "Closed" },
];

const sortOptions = [
  { value: "recent", label: "Recent" },
  { value: "priceLowToHigh", label: "Price Low to High" },
  { value: "priceHighToLow", label: "Price High to Low" },
];

export default function AllAuctions() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });

  const recentLiveAuctions = useSelector(selectors.selectrecentauctions) || {
    auctions: [],
    totalRecords: 0,
  };
  const loading = useSelector(selectors.loading);

  const dispatch = useDispatch();
  let liveAuctions = recentLiveAuctions.auctions;

  const [searchString, setSearchString] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [sortValue, setSortValue] = useState();
  const [numPages, setNumpages] = useState(1);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12);

  const handlePageSelect = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const handlePrev = () => {
    if (page === 0) return;
    const newPage = page - 1;
    setPage(newPage);
  };

  const handleNext = () => {
    if (page === numPages - 1) return;
    const newPage = page + 1;
    setPage(newPage);
  };

  useEffect(() => {
    const newNumPages = Math.ceil(recentLiveAuctions.totalRecords / limit);
    setNumpages(newNumPages);
  }, [recentLiveAuctions.totalRecords, limit]);

  useEffect(() => {
    const payload = {
      herbName: searchString || "",
      status: "live",
      sortBy: sortValue || "",
      page,
      limit,
    };
    dispatch(actions.fetchLiveAuctions(payload));
  }, [dispatch, filterValue, searchString, sortValue, page, limit]);

  return (
    <>
      {loading ? <PreLoader /> : <></>}
      <main className={styles.allAuctions}>
        <header className={styles.pageHeader}>
          <section className={styles.title}>
            <h1>All Auctions</h1>
          </section>
          <section className={styles.inputContainer} id="search_container">
            {/* <Dropdown
              name="Filter"
              options={filterOptions}
              updateOption={(e) => setFilterValue(e.target.value)}
              defaultValue={filterValue}
            /> */}
            <Dropdown 
              name="Sort"
              options={sortOptions}
              updateOption={(e) => setSortValue(e.target.value)}
              defaultValue={sortValue}
              
             
            />
            <SearchInput
              searchString={searchString}
              updateSearchString={setSearchString}
            />
          </section>
        </header>
        <section className={styles.auctionListcontainer}>
         { 
         (liveAuctions.length !== 0) && 
         <div className={styles.auctionList}>
            {liveAuctions.map((auction, index) => (
              <LiveAuctionCard
                key={index}
                url={auction}
                herbName={auction.herbName}
                price={auction.askedPricePerUnit}
                unit={auction.quantityUnit}
                auctionId={auction._id}
                expiryDate={auction.expiryDate}
              />
            ))}
          </div>
          }

         {
          (liveAuctions.length === 0) && 
          <div className={styles.centerText}>No Live Auction available.</div> 
        }

          <div className={styles.paginationContainer}>
            <Pagination
              numPages={numPages}
              page={page}
              handlePageSelect={handlePageSelect}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          </div>
        </section>
      </main>
    </>
  );
}
