import axios from "../axios";
import * as API from "../app/utils/apiHelper";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const login = (payload) => {
  const url = `${API_ENDPOINT}/auth/login`;
  
  return API.post(url, payload);
};

export const logout = () => {
  const url = `${API_ENDPOINT}/auth/logout`;
  return API.get(url);
};

export const fetchMyProfile = (payload) => {
  const url = `${API_ENDPOINT}/users/me`;
  return API.get(url, null);
};

export const fetchmobileExists = (payload) => {
  const url = `${API_ENDPOINT}/users/mobileExists`;
  return API.get(url);
};
export const fetchProfileDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/userDetails/${payload.id}`;
  return API.get(url);
};

export const fetchSellerAuctionCountData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/sellerauctioncount`;
  return API.get(url);
};

export const fetchSellerClosedAuctionData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/sellerclosedauction`;
  return API.get(url);
};

export const fetchSellerLiveAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=live&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyerLiveAuctionsData = (payload = {}) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctions?status=live&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyersListData = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/fetchAllUsers?role=buyer&status=${
    payload.status
  }&name=${payload.name ? payload.name : ""}&page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchSuppliersListData = (payload) => {
  console.log('payload :>> ', payload);
  const url = `${API_ENDPOINT}/adminDashboard/fetchAllUsers?role=seller&status=${
    payload.status
  }&name=${payload.name ? payload.name : ""}&page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchUserAuctionDataDetail = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/auctions/${payload}`;
  return API.get(url);
};

export const fetchHederaAuctionDataDetail = (payload) => {
  const url = `${API_ENDPOINT}/auctions/hederaauction/${payload}`;
  return API.get(url);
};

export const fetchHederaHistoryAuctionDataDetail = (payload) => {
  const url = `${API_ENDPOINT}/auctions/hederaauctionhistory/${payload}`;
  return API.get(url);
};

export const fetchHederaAuctionBidData = (payload) => {
  const url = `${API_ENDPOINT}/auctions/hederaauctionbids/${payload}`;
  return API.get(url);
};

export const fetchHederaHistoryAuctionBidData = (payload) => {
  const url = `${API_ENDPOINT}/auctions/hederaauctionbidshistory/${payload}`;
  return API.get(url);
};

export const fetchUserDetailData = (payload) => {
  const url = `${API_ENDPOINT}/users/userDetails/${payload}`;
  return API.get(url);
};

export const updateUserStatusData = (payload) => {
  const url = `${API_ENDPOINT}/users/updatestatus`;
  return API.put(url, payload);
};

export const blockSeller = (payload) => {
  const url = `${API_ENDPOINT}/users/blockSeller`;
  return API.put(url, payload);
};

export const updateAdminAuctionStatusData = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/auctionstatus`;
  return API.put(url, payload);
};

export const updateAdminAuctionExtendExpiry = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/auctionExtendExpiry`;
  return API.put(url, payload);
};
export const rejectAuctionStatus = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/rejectAuction`;
  return API.put(url, payload);
};

export const cancelAuctionStatus = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/cancelAuction`;
  return API.put(url, payload);
};

export const fetchAdminAuctionListData = (payload) => {
  const url = `${API_ENDPOINT}/adminDashboard/auctions?status=${
    payload.status
  }&name=${payload.name ? payload.name : ""}&page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyerAwardedAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctions?status=awarded&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyerLostAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctions?status=lost&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyerPendingAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctions?status=bid_closed&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchBuyerCancelledAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctions?status=cancelled&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};

export const fetchHomeRecentAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/home/liveauctions`;
  return API.get(url);
};

export const fetchHomeRecentAllAuctionsData = (payload) => {
  console.log(payload);
  const url = `${API_ENDPOINT}/home/liveauctions?limit=${payload.limit}&page=${payload.page}&herbName=${payload.herbName}&status=${payload.status}&sortBy=${payload.sortBy}`;
  return API.get(url);
};

export const fetchHomeRecentAuctionDetailData = (payload) => {
  const url = `${API_ENDPOINT}/home/auctions/${payload}/detail`;
  return API.get(url);
};
export const fetchHomeRecentAuctionDetailDataUnAuthenticated = (payload) => {
  const url = `${API_ENDPOINT}/home/auctions/${payload}/unauthenticatedetail`;
  return API.get(url);
};

export const fetchBuyerAuctionDetailData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctiondetail/${payload}`;
  return API.get(url);
};

export const fetchOpenAuctionDetailData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/openauctiondetail/${payload}`;
  return API.get(url);
};

export const awardAuction = (payload) => {
  const url = `${API_ENDPOINT}/auctions/${payload.auctionId}/award`;
  return API.post(url, payload);
};

export const closeBidsOnAuction = (payload) => {
  const url = `${API_ENDPOINT}/auctions/${payload}/closeauction`;
  return API.post(url, { aucitonid: payload });
};

export const postNewBid = (payload) => {
  const url = `${API_ENDPOINT}/bids`;
  return API.post(url, payload);
};

export const fetchAwardedAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=awarded&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchAwardedPendingAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=bid_closed&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchSellerCancelledAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=cancelled&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchSellerDraftedAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=draft&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchSellerInReviewAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=review&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchSellerRejectedAuctionsData = (payload) => {
  const url = `${API_ENDPOINT}/auctions?status=rejected&name=${
    payload.name ? payload.name : ""
  }&page=${payload.page ? payload.page : 0}&limit=${
    payload.limit ? payload.limit : 10
  }`;
  return API.get(url);
};

export const fetchBuyerAuctionCountData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctioncount`;
  return API.get(url);
};

export const fetchBuyerWonLostData = (payload) => {
  const url = `${API_ENDPOINT}/dashboard/buyerauctionwonlost`;
  return API.get(url);
};

export const saveContactDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/saveContactDetails`;
  return API.post(url, payload);
};

export const savePersonalDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/savePersonalDetails`;
  return API.put(url, payload);
};

export const saveLegalDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/saveLegalDetails`;
  return API.put(url, payload);
};

export const saveAddressDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/saveAddressDetails`;
  return API.put(url, payload);
};

export const updateAddressDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/updateAddressDetails`;
  return API.put(url, payload);
};

export const saveBankDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/saveBankDetails`;
  return API.put(url, payload);
};

export const updateBankDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/updateBankDetails`;
  return API.put(url, payload);
};

export const saveTransactionDetails = (payload) => {
  const url = `${API_ENDPOINT}/users/saveTransactionDetails`;
  return API.put(url, payload);
};

export const generateOtp = (payload) => {
  const url = `${API_ENDPOINT}/otp/generateOtp`;
  return API.post(url, payload);
};

export const generateOtpForLogin = (payload) => {
  const url = `${API_ENDPOINT}/otp/generateLoginOtp`;
  return API.post(url, payload);
};

export const validateOtp = (payload) => {
  const url = `${API_ENDPOINT}/otp/validateOtp`;
  return API.post(url, payload);
};

export const getFileUrl = (payload) => {
  const url = `${API_ENDPOINT}/files/link`;
  return API.post(url, payload);
};

export const uploadFile = (payload) => {
  const { url, data } = payload;
  const sharedAxiosRequestConfig = {
    withCredentials: false,
    url,
  };

  return axios({
    ...sharedAxiosRequestConfig,
    headers: { "Content-Type": "multipart/form-data" },
    method: "PUT",
    data: data,
  });
};

// export const uploadImage = (payload) => {
//   const {url, data} = payload;
//   const sharedAxiosRequestConfig = {
//     withCredentials: false,
//     url
//   }
// }

export const downloadFile = (payload) => {
  const { url } = payload;
  console.log("this api was called", payload);

  const sharedAxiosRequestConfig = {
    withCredentials: false,
    url,
  };

  return axios({
    ...sharedAxiosRequestConfig,
    headers: { "Content-Type": "application/octet-stream" },
    method: "GET",
    responseType: "blob",
  });
};

export const saveAuctionDetails = (payload) => {
  const url = `${API_ENDPOINT}/auctions`;
  return API.post(url, payload);
};
export const saveDraftAuctionDetails = (payload) => {
  const url = `${API_ENDPOINT}/auctions/draft/${payload.id}`;
  return API.put(url, payload);
};
export const removeDraftAuctionImage = (payload) => {
  const url = `${API_ENDPOINT}/auctions/draft/image/${payload.id}`;
  return API.put(url, payload);
};
export const saveImageDetailsForAuction = (payload) => {
  const url = `${API_ENDPOINT}/auctions/saveImageDetails`;
  return API.put(url, payload);
};
export const saveCollectionMethodDetails = (payload) => {
  const url = `${API_ENDPOINT}/auctions/${payload.id}/addcollection`;
  return API.put(url, payload);
};
export const saveQualityDetails = (payload) => {
  const url = `${API_ENDPOINT}/auctions/${payload.id}/addquality`;
  return API.post(url, payload);
};
export const saveTraceabilityDetails = (payload) => {
  const url = `${API_ENDPOINT}/auctions/${payload.id}/addtraceability`;
  return API.post(url, payload);
};

// country, state, city

export const getCountries = (payload) => {
  const url = `${API_ENDPOINT}/countries`;
  return API.get(url, payload);
};

export const getStates = (payload) => {
  const url = `${API_ENDPOINT}/states/${payload}`;
  return API.get(url);
};

export const getCities = (payload) => {
  console.log(payload, "get cities api call");
  const url = `${API_ENDPOINT}/cities/${payload}`;
  return API.get(url);
};

export const fetchPlants = (payload) => {
  const url = `${API_ENDPOINT}/plant`;
  return API.get(url);
};

export const savePlants = (payload) => {
  const url = `${API_ENDPOINT}/plant/createPlant`;
  return API.post(url, payload);
};

export const fetchHomeLinksData = (payload) => {
  const url = `${API_ENDPOINT}/link`;
  return API.get(url);
};

export const fetchLinksListData = (payload) => {
  const url = `${API_ENDPOINT}/link/?page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}`;
  return API.get(url);
};
export const fetchLinkData = (payload) => {
  const url = `${API_ENDPOINT}/link/${payload}`;
  return API.get(url);
};

export const createLinkData = (payload) => {
  const url = `${API_ENDPOINT}/link/createLink`;
  return API.post(url,payload);
};

export const updateLinkData = (payload) => {
 
  let { id, ...rest } = payload;
  const url = `${API_ENDPOINT}/link/updateLink/${id}`;
  return API.put(url, rest);
};
export const removeLinkData = (payload) => {
  const url = `${API_ENDPOINT}/link/${payload.id}`;
  return API.deleteResource(url, payload);
};

export const contactUsForm = (payload) => {
  const url = `${API_ENDPOINT}/home/contactus`;
  return API.post(url, payload);
};


export const fetchHomeMandisData = (payload) => {
  const url = `${API_ENDPOINT}/mandi/all`;
  return API.get(url);
};


export const fetchMandisListData = (payload) => {
  const url = `${API_ENDPOINT}/mandi/all/?page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};
export const fetchMandiData = (payload) => {
  const url = `${API_ENDPOINT}/mandi/${payload}`;
  return API.get(url);
};

export const createMandiData = (payload) => {
  const url = `${API_ENDPOINT}/mandi/new`;
  return API.post(url,payload);
};

export const updateMandiData = (payload) => {
 
  let { id, ...rest } = payload;
  const url = `${API_ENDPOINT}/mandi/edit/${id}`;
  return API.put(url, rest);
};
export const removeMandiData = (payload) => {
  const url = `${API_ENDPOINT}/mandi/del/${payload.id}`;
  return API.deleteResource(url, payload);
};


// ---------------------aboutapi-----------------


export const fetchHomeAboutsData = (payload) => {
  const url = `${API_ENDPOINT}/about/all`;
  return API.get(url);
};


export const fetchAboutsListData = (payload) => {
  const url = `${API_ENDPOINT}/about/all/?page=${
    payload.page ? payload.page : 0
  }&limit=${payload.limit ? payload.limit : 10}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`;
  return API.get(url);
};
export const fetchAboutData = (payload) => {
  const url = `${API_ENDPOINT}/about/${payload}`;
  return API.get(url);
};

export const createAboutData = (payload) => {
  const url = `${API_ENDPOINT}/about/new`;
  return API.post(url,payload);
};

export const updateAboutData = (payload) => {
 
  let { id, ...rest } = payload;
  const url = `${API_ENDPOINT}/about/edit/${id}`;
  return API.put(url, rest);
};
export const removeAboutData = (payload) => {
  const url = `${API_ENDPOINT}/about/del/${payload.id}`;
  return API.deleteResource(url, payload);
};


export const checkDelivery = (payload) => {
  const url = `${API_ENDPOINT}/auctions/checkDelivery/${payload.auctionId}/${payload.pincode}`;
  return API.get(url, payload);
};
export const getShppingPrice = (payload) => {
  const url = `${API_ENDPOINT}/auctions/shippingPrice`;
  return API.get(url, payload);
};