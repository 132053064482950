import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import { formattedDate } from "../../../../utils/helper";

const AuctionCustomTable = ({
  heading,
  filteredData,
  bids,
  onRowClick,
  type,
  dropDownValue,
  sortConfig,
  setSortConfig
}) => {

  const handleSort = (key) => {
    // Determine the new sort direction
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    setSortConfig({ key, direction });
  };

 
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return "⇵"; // Default sort icon
  };
  return (
    <>
      <Table responsive>
      <thead style={{ backgroundColor: "#F7FAFC" }}>
        <tr>
          <td className={styles.headT} onClick={() => handleSort("auctionID")}>
            Auction ID
          </td>
          <td
            className={styles.headT}
            onClick={() => handleSort("supplierName")}
          >
            Supplier Name {renderSortIcon("supplierName")}
          </td>
          <td className={styles.headT} onClick={() => handleSort("herbName")}>
            Herb Name {renderSortIcon("herbName")}
          </td>
          <td className={styles.headT} onClick={() => handleSort("createdDate")}>
            Created Date {renderSortIcon("createdDate")}
          </td>
          <td className={styles.headT} onClick={() => handleSort("noOfbids")}>
            No. of Bids 
          </td>
          <td className={styles.headT} onClick={() => handleSort("quantity")}>
            Quantity (KG)  {renderSortIcon("quantity")}
          </td>
          <td
            className={styles.headT}
            onClick={() => handleSort("askedPricePerUnit")}
          >
            Asked Price Per Unit {renderSortIcon("askedPricePerUnit")}
          </td>
          <td className={styles.headT} onClick={() => handleSort("status")}>
            Status
          </td>
        </tr>
      </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr
              key={index}
              onClick={() => {
                onRowClick(data.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <td className={styles.dataT}>{data.auctionID}</td>
              {/* <td className={styles.dataT}>{data.supplierID}</td> */}
              <td className={styles.dataT}>{data.supplierName}</td>
              <td className={styles.dataT}>
                <span>{data.herbName}</span>
              </td>
              <td className={styles.dataT}>
                <span>{formattedDate(data.createdDate)}</span>
              </td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.noOfbids}</span>
                    </td>
                  )} */}
              <td className={styles.dataT}>
                <span>{data.noOfbids}</span>
              </td>
              <td className={styles.dataT}>
                <span>{data.quantity}</span>
              </td>
              <td className={styles.dataT}>
                <span>{data.askedPricePerUnit}</span>
              </td>
              {/* {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.highbid}</span>
                    </td>
                  )} */}
              <td className={styles.dataT}>
                <span>{data.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AuctionCustomTable;
