import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchAbout({ payload }) {
  try {
    const res = yield call(API.fetchHomeAboutsData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { body } = parsedObject;
    yield put(actions.fetchAboutsSuccess(body));
  } catch (err) {
    yield put(actions.fetchAboutsFailure(err?.response?.data));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* abotListingSaga() {
  yield takeLatest(actions.fetchAbouts.type, handleFetchAbout);
  
}
