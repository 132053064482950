import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./style.module.css";
import Heading from "../../../components/shared/Heading";
import SearchIcon from "../../../../assets/dashboardImages/searchBlack.svg";
import CustomTable from "../../../components/dashboardApp/customTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";

import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { labListingSaga } from "./saga";
import * as selectors from "./selectors";
import {
  highestBid,
  formattedDate,
  filterMyBids,
  formatedAuctionId,
} from "../../../utils/helper";

import { useAuth } from "../../../contexts/auth";
import BuyerWonAuction from "../../../components/dashboardApp/customTable/buyerwonauciton";

const WonAuction = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: labListingSaga });
  const dispatch = useDispatch();
  const auth = useAuth();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [sortConfig, setSortConfig] = useState({ key: "createdDate", direction: "desc" });

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const payload = {
      name: searchQuery,
      page: currentPage - 1,
      limit: itemsPerPage,
      sortField:sortConfig.key,
      sortOrder:sortConfig.direction
    };
    dispatch(actions.fetchWonAuctions(payload));
  }, [dispatch, searchQuery, currentPage, itemsPerPage, sortConfig]);

  const awardedAuctions = useSelector(selectors.selectwonauctions) || {
    auctions: [],
    totalRecords: 0,
  };

  const liveAuctionList = [];
  const liveAuctionHeading = [
    {
      id: "ID",
      createdDate: "Created date",
      auction: "Auction",
      nob: "No of bids",
      quantity: "Quantity (Kg)",
      asked: "Ask price/Kg (₹)",
      highbid: "H1 bid/Kg (₹)",
      mybid: "My bid/Kg (₹)",
    },
  ];

  const dbAuctions = awardedAuctions.auctions;

  if (dbAuctions.length && auth.loggedInUser) {
    for (let i = 0; i < dbAuctions.length; i++) {
      let bids = dbAuctions[i].bids || [];
      let hb = highestBid(bids);
      let mybids = filterMyBids(bids, auth.loggedInUser._id);
      let myJHB = highestBid(mybids);
      liveAuctionList.push({
        id: dbAuctions[i]["_id"],
        auctionId: dbAuctions[i]["auctionIdForDisplay"]
          ? formatedAuctionId(dbAuctions[i]["auctionIdForDisplay"])
          : "___", //"HC00"+i,
        auction: dbAuctions[i]["herbName"], // "Rosemary",
        createdDate: formattedDate(dbAuctions[i]["createdAt"]), // '24 May 2022',
        nob: bids.length, //"3",
        quantity: dbAuctions[i]["totalQuantity"], //"20",
        asked: dbAuctions[i]["askedPricePerUnit"], //"10",
        highbid: hb ? hb.amount : "--", //"11",
        mybid: myJHB ? myJHB.amount : "--", //"11",
      });
    }
  }

  const totalCount = awardedAuctions.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <section>
        <Container fluid>
          <Row>
            <Col lg={9} className={styles.marginBottom36}>
              <Heading text="Won auctions" />
            </Col>
            {/* priority is slow of customize view */}
            {/* <Col lg={2} className={styles.marginBottom36}><Button outline text="Customize view"/></Col> */}
            <Col lg={3} className={styles.marginBottom36}>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className={["input-group-text", styles.basic_addon].join(
                      " "
                    )}
                    id="basic-addon1"
                  >
                    <img src={SearchIcon} alt="" />
                  </span>
                </div>
                <input
                  type="text"
                  id={styles.search}
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <BuyerWonAuction
                liveAuctionHeading={liveAuctionHeading}
                filteredData={liveAuctionList}
                bids={false}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>
    </>
  );
};

export default WonAuction;
