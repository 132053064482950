import React, { useEffect ,useState} from "react";
import styles from "./styles.module.css";
import Accordian from "../../../components/homeApp/accordian";
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { abotListingSaga } from '../aboutMedicinalPlants/saga';
import { useDispatch, useSelector } from 'react-redux';
import { sliceKey, reducer, actions, initialState } from "../aboutMedicinalPlants/slice";
import * as selectors from "../aboutMedicinalPlants/selectors";

export default function AgroTech() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: abotListingSaga });
  const dispatch = useDispatch();
  const dbAboutList = useSelector(selectors.selectAboutList) || [];

  const [aboutData,SetAboutData] = useState([]);
  useEffect(() => {
    let filterItem =dbAboutList?.aboutItems?dbAboutList?.aboutItems.filter(item=>(item.type =="agroTechniques" && item.status==true)):[];

    SetAboutData(filterItem)
  }, [dbAboutList]);


  useEffect(() => {

    dispatch(actions.fetchAbouts());
  
  }, [dispatch]);
  const unescapeHtml = (escapedHtml) =>
    escapedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  return (
    <div className={styles.agroTech}>
      <h1>Agro-techniques of medicinal plants</h1>
      {aboutData.map((item, index) => (
        <Accordian key={index} title={item.title}>
          <div dangerouslySetInnerHTML={{ __html: unescapeHtml(item.content) }} />

        </Accordian>
      ))}
      {/* <Accordian title="Do's and don'ts">
        <ul>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Good%20agricultural%20practices%20for%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Good agricultural practices for medicinal plants
            </a>
          </li>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Good%20field%20collection%20practices%20for%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Good field collection practices for medicinal plants
            </a>
          </li>
          <li>
            <a
              href="https://echarak.in/echarak/templates/Post-harvest%20management%20of%20medicinal%20plants.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Post-harvest management of medicinal plants
            </a>
          </li>
        </ul>
      </Accordian> */}
      
    </div>
  );
}
