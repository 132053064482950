import { Col, Container, Row,Button } from "react-bootstrap";
import UsersCustomTable from "../../../components/dashboardApp/adminCustomTable/userTable";
import TableFooter from "../../../components/dashboardApp/tableFooter";
import styles from "./links.module.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mandiListingSaga } from "./saga";
import { sliceKey, reducer, actions, initialState } from "./slice";
import * as selectors from "./selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { toastSuccess } from "../../../utils/toastHelper";
import { confirmAlert } from 'react-confirm-alert';


const MandiAdmin =()=>{
  
  const navigate = useNavigate();

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: mandiListingSaga });
  const dispatch = useDispatch();
  const dbMandiList = useSelector(selectors.selectMandiList) || initialState;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [fromPage, setFromPage] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    // Perform any other actions based on the new items per page value
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "createdAt", direction: "desc" });

  const totalCount = dbMandiList.totalRecords;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    const payload = {
      page: currentPage - 1,
      limit: itemsPerPage,
      sortField:sortConfig.key,
      sortOrder:sortConfig.direction
    };
    dispatch(actions.fetchMandis(payload));
  
  }, [dispatch,  currentPage, itemsPerPage, sortConfig]);
  const handleMandiEdit=(linkId)=>{
    // Find the entry to be edited
    navigate(`/dashboard/admin/mandi/edit/${linkId}`);

    
  };
  const handleMandiDelete = (linkId) => {

    confirmAlert({
      message: `Are you sure you want to delete this ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>{
            const payload = {
              data: {
                id: linkId,
              },
              onSuccess: () => {
                toastSuccess("Mandi Deleted successfully");
                const payload = {
                  page: currentPage - 1,
                  limit: itemsPerPage,
                };
                dispatch(actions.fetchMandis(payload));
                navigate("/dashboard/admin/mandi");
              },
            };
            dispatch(actions.removeMandi(payload));
          }
        },
        {
          label: 'No'
        }
      ]
    });
   
  };

  



const allMandisList = [];
const mandiHeadings = [
  {
    Sno:"SNo",
    BotanicalName:"Botanical Name",
    TradeName: "TradeName",
    Parts: "Parts",
    Amritsar: "Amritsar",
    Delhi: "Delhi",
    Saharanpur: "Saharanpur",
  },
];

if (dbMandiList.mandiItems && dbMandiList.mandiItems.length) {
  for (let i = 0; i < dbMandiList.mandiItems.length; i++) {
    
    allMandisList.push({
      mandiId:dbMandiList.mandiItems[i]["_id"],
      botanicalName:dbMandiList.mandiItems[i]["botanicalName"],
      tradeName: dbMandiList.mandiItems[i]["tradeName"],
      parts: dbMandiList.mandiItems[i]?.["parts"]||[],
      amritsar: dbMandiList.mandiItems[i]?.["rates"]?.["amritsar"]||"NA",
      delhi: dbMandiList.mandiItems[i]?.["rates"]?.["delhi"] || "NA",
      saharanpur: dbMandiList.mandiItems[i]?.["rates"]?.["saharanpur"] || "NA", 
    });
    
  }
}





  return (
    <>
  <section>
        <Container fluid>
          <Row>
            <Col lg={10} className={styles.marginBottom36}>
              <h2>Mandi</h2>
            </Col>
          

            <Col lg={2} className={`text-right ` }>
            {/* <Mandi className='nav-link' to="/dashboard/admin/links/add">
            Add
          </Mandi> */}
              <Button variant="primary" style={{position:"absolute", top:"89px", right:"48px"}} 
              onClick={() => navigate("/dashboard/admin/mandi/add", { state: { changeMandiData: dbMandiList } })}>
 
              {/* // onClick={() => navigate("/dashboard/admin/mandi/add")} 
              // changeMandiData={changeMandi} >
                Add */}
                Add
              </Button>
            </Col>
         
          </Row>
          <Row>
            <Col lg={12} className={styles.cardTable}>
              <UsersCustomTable
                heading={mandiHeadings}
                filteredData={allMandisList}
                onRowClick={handleMandiEdit}
                handleLinkDelete={handleMandiDelete}
                type={"mandi"}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            </Col>
          </Row>

          {pageCount > 1 ? (
            <TableFooter
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              currentPage={currentPage}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              totalCount={totalCount}
              fromCount={fromPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </section>

    </>
  );
};

export default MandiAdmin;