import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ label, futureDate, place }) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentDate = new Date();
            const targetDate = new Date(futureDate);

            // Calculate the difference in milliseconds
            const timeDiff = targetDate - currentDate;

            if (timeDiff <= 0) {
                setTimeLeft('');
                return;
            }

            //   const seconds = Math.floor((timeDiff / 1000) % 60);
            //   const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
            //   const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
            //   const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            // //   const months = Math.floor(days / 30);
            // //   const years = Math.floor(months / 12);

            //   // Format the output
            //   const formattedTime = ` ${days % 30} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

            //   setTimeLeft(formattedTime);
            const totalSeconds = Math.floor(timeDiff / 1000); // Total remaining seconds
            const hours = Math.floor(totalSeconds / 3600); // Total hours
            const minutes = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes
            const seconds = totalSeconds % 60; // Remaining seconds
            let formattedTime = ``;
            if (hours > 0) {
                formattedTime += `${hours} :`
            }
            if (minutes > 0) {
                formattedTime += `${minutes} :`
            }
            formattedTime += ` ${seconds}`

            // Format the output
            setTimeLeft(formattedTime);
        };

        const timerId = setInterval(calculateTimeLeft, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timerId);
    }, [futureDate]);

    return (
        <div>
            {/* <h2>Time Remaining Until {new Date(futureDate).toLocaleString()}</h2> */}
            {timeLeft != "" && (
                <>
                    {place == "list" ?
                        (<> <span style={{ backgroundColor: "#FFE5B0", width: "275px", color: "green",position:"relative", padding: "3px",fontSize:"10px",  borderRadius: "5px" }}>{label} </span>
                            <span  style={{ backgroundColor: "#FFE5B0", width: "275px", color: "green",top:"26px",left:"-21px", position:"absolute",fontSize:"17px", padding: "3px", borderRadius: "5px" }}> {timeLeft}</span></>)
                        :
                        (<p style={{ backgroundColor: "yellow", width: "275px", color: "black", padding: "5px", borderRadius: "5px" }}>{label} in : {timeLeft} hrs</p>)
                    }
                </>
            )}

        </div>
    );
};

export default CountdownTimer;
