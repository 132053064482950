import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/apis";



export function* handleFetchAbouts({ payload }) {
  try {
    const res = yield call(API.fetchAboutsListData, payload);    
    const parsedObject = JSON.parse(res.data);    
    const { body } = parsedObject;
    yield put(actions.fetchAboutsSuccess(body));
  } catch (err) {
    yield put(actions.fetchAboutsFailure(err?.response?.data));
  }
}


export function* handleDeleteAbout({ payload }) {
  try {
    const res = yield call(API.removeAboutData, payload.data);    
    const parsedObject = JSON.parse(res.data);      
    const { data } = parsedObject;
    yield put(actions.removeAboutSuccess(data));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.removeAboutFailure(err?.response?.data));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* aboutListingSaga() {
  yield takeLatest(actions.fetchAbouts.type, handleFetchAbouts);
  yield takeLatest(actions.removeAbout.type, handleDeleteAbout);
  
}
