import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  homeAboutList:{AboutItems:[],totalRecords:0},
};

const homeAboutList = createSlice({
  name: "homeAboutList",
  initialState,
  reducers: {
    fetchAbouts(state, action) {
      state.loading = true;
    },
    fetchAboutsSuccess(state, action) {
      console.log('action.payload :>> ', action.payload);
      state.homeAboutList = action.payload; 
      state.loading = false;
    },
    fetchAboutsFailure(state, action) {
      state.loading = false;
    },
  },
 
});

export const { actions, reducer, name: sliceKey } = homeAboutList;
