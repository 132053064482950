import React, { useEffect,useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Heading from "../../../components/shared/Heading";
import Overview from "./overview";
import Traceability from "./traceability";
import Quality from "./quality";
import Supplier from "./supplier";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import rightArrow from "../../../../assets/dashboardImages/rightArrow.svg";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

import { sliceKey, reducer, actions } from "./slice";
import { auctionDetailSaga } from "./saga";
import * as selectors from "./selectors";
import { toastSuccess } from "../../../utils/toastHelper";
import Modal from "../../../components/homeApp/modal";
import Button from "../../../components/shared/button";
import { formatedAuctionId } from "../../../utils/helper";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import CountdownTimer from "./CountdownTimer";
const AuctionDetail = () => {
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: auctionDetailSaga });
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonForBlock, setReasonForBlock] = useState("");
  const [reasonForBlockError, setReasonForBlockError] = useState("");
  const [modalAction, setmodalAction] = useState("");
  const showModal = (action) => {
    setmodalAction(action);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAuctionCancleAndReject = (e) => {
    e.preventDefault();
    if (reasonForBlock.length <= 3) {
      setReasonForBlockError(
        "please, provide a valid reason for rejection this Auction."
      );
      return;
    }
    
if(modalAction ==="Reject"){
  dispatch(
    actions.rejectAuctionStatus({
      id: dbAuction._id,
      reason: reasonForBlock,
      onSuccess: () => {
        toastSuccess("Auction marked as Rejected.");
        setIsModalOpen(false);
        navigate('/dashboard/admin/auctions/');
      },
    })
  );
}
else{
  dispatch(
    actions.cancelAuctionStatus({
      id: dbAuction._id,
      reason: reasonForBlock,
      onSuccess: () => {
        toastSuccess("Auction marked as Cancelled.");
        setIsModalOpen(false);
        navigate('/dashboard/admin/auctions/');
      },
    })
  );
}
    
    
  };

  const qualityHeading = [
    {
      parameter: "Parameter",
      value: "Value",
    },
  ];
  
  const handleAuctionStatusChange = (status) => {
   confirmAlert({
      message: `Are you sure you want to mark this Auction as ${status}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => 
            dispatch(
              actions.updateAuctionStatus({
                id: dbAuction._id,
                status: status,
                onSuccess: () => {
                  toastSuccess("Auction updated successfully.");
                  dispatch(actions.fetchAuctionById(dbAuction._id));
                },
              })
            )
        },
        {
          label: 'No'
        }
      ]
    });
    //console.log(move);
    // let move = window.confirm(
    //   `Are you sure you want to mark this Auction as ${status}?`
    // );
    // if (!move) {
    //   return;
    // }
    // dispatch(
    //   actions.updateAuctionStatus({
    //     id: dbAuction._id,
    //     status: status,
    //     onSuccess: () => {
    //       toastSuccess("Auction updated successfully.");
    //       dispatch(actions.fetchAuctionById(dbAuction._id));
    //     },
    //   })
    // );
    
  };
  const handleExtendExpiry = (status) => {
    confirmAlert({
       message: `Are you sure you want to extend this Auction for 48 hours ?`,
       buttons: [
         {
           label: 'Yes',
           onClick: () => {
             dispatch(
               actions.updateAuctionExtendedExpiry({
                 id: dbAuction._id,
                 onSuccess: () => {
                   toastSuccess("Auction extended expiry successfully.");
                   dispatch(actions.fetchAuctionById(dbAuction._id));
                 },
               })
             )
            }
         },
         {
           label: 'No'
         }
       ]
     });
     
   };
 


  useEffect(() => {
    let url = new URL(window.location.href);
    const auctionId = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);
    dispatch(actions.fetchAuctionById(auctionId));
  }, [dispatch]);

  const auction = useSelector(selectors.auctionDetail) || {};
  const dbAuction = auction.auctionDetail ? auction.auctionDetail : null;
  
  const qualityData = [];
  if (dbAuction && dbAuction.qualityResult && dbAuction.qualityResult.length) {
    for (let i = 0; i < dbAuction.qualityResult.length; i++) {
      qualityData.push({
        parameter: dbAuction.qualityResult[i]["parameter"], //'Parameter',
        value: dbAuction.qualityResult[i]["value"], //'Value'
      });
    }
  }

  return (
    <div>
      {dbAuction && (
        <Container fluid id="auctionD">
          <Row style={{ marginBottom: "2em" }}>
            <Col lg={1}>
              <Link className={[styles.visitBtn].join(" ")} to={"/dashboard/admin/auctions"}>
                <img
                  src={rightArrow}
                  
                  alt=""
                />
              </Link>
            </Col>
            <Col lg={5}>
              <Heading text={dbAuction.herbName} />
              <span className="auctionID">{formatedAuctionId(dbAuction.auctionIdForDisplay)} </span>
            </Col>
            {/* <Col lg={2}><Link onClick={()=>{handleVisitAuction()}} to={"#"} className={[styles.visitBtn, 'float-end'].join(' ')}>Visit auction</Link></Col> */}
            
            <Col lg={3}>
            {dbAuction.status === "cancelled" && dbAuction?.expiryDate&& (

              <CountdownTimer label={"Auction closed"} futureDate={dbAuction.expiryDate} />
            )}
            </Col>
            <Col lg={3}>
              {dbAuction.status === "live" && (
                <Link
                  onClick={() => {
                  //  handleAuctionStatusChange("cancelled");
                  showModal('Cancel');
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                  style={{
                    borderColor: "red",
                    color: "red",
                    marginLeft: "1em",
                  }}
                >
                  Cancel
                </Link>
              )}
              {dbAuction.status === "review" && (
                <Link
                  onClick={() => {
                   // handleAuctionStatusChange("rejected");
                   showModal('Reject');
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                  style={{
                    borderColor: "red",
                    color: "red",
                    marginLeft: "1em",
                  }}
                >
                  Reject
                </Link>
                
              )}
              {dbAuction.status === "review" && (
                <Link
                  onClick={() => {
                    handleAuctionStatusChange("live");
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                  style={{
                    borderColor: "green",
                    color: "green",
                    marginLeft: "1em",
                  }}
                >
                  Live
                </Link>
                
              )}
              {dbAuction.status === "cancelled" && (
                <Link
                  onClick={() => {
                    handleExtendExpiry();
                  }}
                  to={"#"}
                  className={[styles.visitBtn, "float-end"].join(" ")}
                  style={{
                    borderColor: "green",
                    color: "green",
                    marginLeft: "1em",
                  }}
                >
                  Extend the Auction
                </Link>
                
              )}
            </Col>

            <div style={{ marginTop: "2em" }}>
              <Tabs
                defaultActiveKey="overview"
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab eventKey="overview" title="Overview">
                  <Overview
                    auctionDetail={dbAuction}
                    highestBidAmount={auction.highestBidAmount}
                  />
                </Tab>
                {/* <Tab eventKey="auctionDetail" title="Auction details">
                            profile
                        </Tab> */}
                <Tab eventKey="traceability" title="Traceability">
                  <Traceability auctionDetail={dbAuction} />
                </Tab>
                <Tab eventKey="qualityDetail" title="Quality Details">
                  <Quality
                    listHeading={qualityHeading}
                    listData={qualityData}
                    auctionDetail={dbAuction}
                  />
                </Tab>
                {/* <Tab eventKey="supplierDetail" title="Supplier Details">
                            <Supplier />
                        </Tab> */}
              </Tabs>
            </div>
          </Row>
        </Container>
      )}

{isModalOpen &&
                <Modal
                  showModal={isModalOpen}
                  closeButton={true}
                  closeModal={closeModal}
                  modalStyle={{ width: "650px" }}
                >
                  <h4
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "400",
                      fontSize: "18px",
                      marginBottom: "20px",
                    }}
                  >
                    {modalAction ==="Reject" ? 'Reject ' : 'Cancel '}
                    <span style={{ fontWeight: "500" }}>
                    {formatedAuctionId(dbAuction.auctionIdForDisplay)}
                    </span>
                  </h4>
                  <form onSubmit={handleAuctionCancleAndReject}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        marginBottom: "16px",
                      }}
                    >
                      <label htmlFor="reasonForBlocking">
                      {modalAction ==="Reject" ? 'Rejection Reason' : 'Cancellation Reason'}
                      </label>
                      <textarea
                        rows="5"
                        cols="5"
                        value={reasonForBlock}
                        onChange={(e) => setReasonForBlock(e.target.value)}
                        placeholder={modalAction ==="Reject" ? "Reason for rejection" : "Reason for cancellation"} 
                        style={
                          reasonForBlockError
                            ? {
                                width: "70%",
                                outline: "none",
                                padding: "12px",
                                border: "1px solid #d00",
                                borderRadius: "6px",
                              }
                            : {
                                width: "70%",
                                outline: "none",
                                padding: "12px",
                                border: "1px solid #ddd",
                                borderRadius: "6px",
                              }
                        }
                      />
                      {reasonForBlockError ? (
                        <span style={{ color: "#d00" }}>
                          {reasonForBlockError}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="consent"
                        name="consent"
                        checked
                        defaultChecked={true}
                      />
                      <label htmlFor="consent">
                        Are you sure, you want to {modalAction ==="Reject" ? 'Reject ' : 'Cancel '}{" "}
                        <span
                          style={{
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {formatedAuctionId(dbAuction.auctionIdForDisplay)}
                        </span>
                      </label>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button solid={true} text="Update" type="submit" />
                    </div>
                  </form>
                </Modal>
              }
    </div>
  );
};

export default AuctionDetail;
