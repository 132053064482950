import React from "react";
import { Row, Col} from 'react-bootstrap';
import Heading from "../../../../components/shared/Heading";
import hederaLogo from "../../../../../assets/icons/Hedera logo.svg";
import styles from "./style.module.css";
import { formattedDateForTrace } from "../../../../utils/helper";

const TrackingDetails = ({trackingDetails}) => {
    const divStyle = {width: "100%",
    height: 40,
    backgroundColor: "#F7FAFC",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",};
    return (
        <>
            <Row>
                <Col lg={12} className={styles.rowStyle}>
                    {trackingDetails  &&
                        <Row>
                        {Object.keys(trackingDetails).map((key, index) => (
                            <>
                                <Col lg={6} key={index} className={styles.paddingCol}><span style={{textTransform: "capitalize"}} className={styles.headingText}>{key.replace("_", " ")}</span> :<span className={styles.headingText}>{trackingDetails[key]}</span></Col>
                            </>
                        ))}
                        </Row>
                    }
                </Col>
            </Row>
        </>
    );
}

export default TrackingDetails;