// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_auctionID__kZhaQ {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4a5568;
  }
  .style_visitBtn__gm2-B {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1a202c;
    padding: 5px 15px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    text-decoration: none;
  }
  .style_tabDiv__X4it5 {
    border-width: 1.5px 0px;
    border-style: solid;
    border-color: #e2e8f0;
  }
  
  input[type="checkbox"],
  input[type="checkbox"]:hover {
    accent-color: #38a16933;
    width: 16px;
    height: 16px;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/pages/admindashboardApp/aboutEdit/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;EACA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;EACvB;EACA;IACE,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;;IAEE,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":[".auctionID {\n    font-family: \"Inter\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #4a5568;\n  }\n  .visitBtn {\n    font-family: \"Inter\";\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #1a202c;\n    padding: 5px 15px;\n    border: 1px solid #e2e8f0;\n    border-radius: 6px;\n    text-decoration: none;\n  }\n  .tabDiv {\n    border-width: 1.5px 0px;\n    border-style: solid;\n    border-color: #e2e8f0;\n  }\n  \n  input[type=\"checkbox\"],\n  input[type=\"checkbox\"]:hover {\n    accent-color: #38a16933;\n    width: 16px;\n    height: 16px;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auctionID": `style_auctionID__kZhaQ`,
	"visitBtn": `style_visitBtn__gm2-B`,
	"tabDiv": `style_tabDiv__X4it5`
};
export default ___CSS_LOADER_EXPORT___;
