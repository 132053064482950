import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import PlaceBid from "../PlaceBidInputButton";
import { Link } from "react-router-dom";

const BuyerLiveAuctionTable = ({
  liveAuctionHeading,
  filteredData,
  bids,
  fetchAllAuction,
  sortConfig,
  setSortConfig
}) => {
  


  const handleSort = (key) => {
    // Determine the new sort direction
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    setSortConfig({ key, direction });
  };

 
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return "⇵"; // Default sort icon
  };


  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {liveAuctionHeading.map((auction, index) => (
            <tr key={index}>
              <td className={styles.headT} onClick={() => handleSort("auctionIdForDisplay")}>{auction.id} {renderSortIcon("auctionIdForDisplay")}</td>
              <td className={styles.headT} onClick={() => handleSort("herbName")}>{auction.auction } {renderSortIcon("herbName")}</td>
              {/* <td className={styles.headT}>{auction.createdDate}</td> */}
              <td className={styles.headT}
              //  onClick={() => handleSort("bids")}
               >{auction.nob} 
               {/* {renderSortIcon("bids")} */}
               </td>
              <td className={styles.headT} onClick={() => handleSort("totalQuantity")}>{auction.quantity} {renderSortIcon("totalQuantity")}</td>
              <td className={styles.headT} onClick={() => handleSort("askedPricePerUnit")}>{auction.asked} {renderSortIcon("askedPricePerUnit")}</td>
              <td className={styles.headT} 
              // onClick={() => handleSort("Hbids")}
              >{auction.highbid} 
              {/* {renderSortIcon("Hbids")} */}
              </td>
              <td className={styles.headT} 
              // onClick={() => handleSort("Mbids")}
              >{auction.mybid}
               {/* {renderSortIcon("Mbids")} */}
               </td>
              <td className={styles.headT} >{auction.placebid}</td>
            </tr>
          ))}
        </thead>
        <tbody id="bidwith">
          {filteredData.map((auction, index) => (
            <tr key={index}>
              <td className={styles.dataT}>{auction.auctinId}</td>                        
              <td className={styles.dataT}>
                {/* <a href={"live-auctions/auction-detail?id="+auction.id} className={styles.greenT}>{auction.auction}</a> */}
                <Link
                  to={"auction-detail?id=" + auction.id}
                  className={styles.greenT}
                >
                  {auction.auction}
                </Link>
              </td>
              {/* <td className={styles.dataT}>{auction.auction}</td> */}
              {/* <td className={styles.dataT}>{auction.createdDate}</td> */}
              <td className={styles.dataT}>
                <span>{auction.nob}</span>
              </td>
              <td className={styles.dataT}>
                <span>{auction.quantity}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.asked}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.highbid}</span>
              </td>
              <td className={styles.dataT}>
                <span>₹{auction.myhighbid}</span>
              </td>
              {/* <td className={styles.dataT}><span>{auction.highbid+''+index}</span>  <sup><Badge className={styles.badgeHigh}>{auction.hightext +''+(index+1)}</Badge></sup></td> */}
              {/* <td className={styles.dataT}><span>{auction.mybid+''+index}</span></td>*/}
              {
                <td style={{ padding: "0.8em 1.5em"}} id="bidwith1" >
                  <PlaceBid 
                    placeholderText="Enter price "
                    
                    asked={auction.asked}
                    highbid={auction.highbid}
                    auctionID={auction.id}
                    bidIncrementAmount={auction.bidIncrementAmount}
                    myHighestBid={auction.myhighbid}
                    fetchAllAuction={fetchAllAuction}
                    buttonText="Bid"
                  />
                </td>
              }
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BuyerLiveAuctionTable;
